import { useState,  useEffect } from 'react';
import { SupersetClient } from '@superset-ui/core';
import getBootstrapData from 'src/utils/getBootstrapData';
import { getClientErrorObject } from 'src/utils/getClientErrorObject';

function useUser() {
  const [isBytearkAdmin, setIsBytearkAdmin] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const bootstrapData = getBootstrapData();
  const { user } = bootstrapData;

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const email = user?.email;

  const isAuthed = !!firstName && !!lastName;

  useEffect(() => {
    SupersetClient.get({
      endpoint: '/api/v1/workspace/is_byteark_admin',
    })
      .then(({ json }) => {
        const isBytearkAdmin = json.result;
        setIsBytearkAdmin(isBytearkAdmin);
      })
      .catch(response => {
        getClientErrorObject(response).then(e => {
          console.log('error in getting admin state', e);
        })
      })
    SupersetClient.get({
      endpoint: '/api/v1/workspace/is_user_admin',
    })
      .then(({ json }) => {
        const isUserAdmin = json.result;
        setIsUserAdmin(isUserAdmin);
      })
      .catch(response => {
        getClientErrorObject(response).then(e => {
          console.log('error in getting user admin state', e);
        })
      })
  }, []);

  return {
    isAuthed,
    firstName,
    lastName,
    email,
    isBytearkAdmin,
    isUserAdmin,
  };
}

export default useUser;
